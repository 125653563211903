import React, { useState } from 'react';

import { Container, Row, Col, Card } from "react-bootstrap"; 
import "bootstrap/dist/css/bootstrap.min.css"; 

import { GiCommercialAirplane } from "react-icons/gi";

import { BsPassportFill } from "react-icons/bs";
import axios from 'axios';





function Home() {

  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [messages, setMessage] = useState('');


  const sendMes=(e) =>{

    e.preventDefault();
    let fData = new FormData();
    fData.append('email', email);
    fData.append('fullname', fullname);
    fData.append('messages', messages);
    
    
  
     axios.post('https://euro-employment.ayomitech.com/api/contact.php', fData)
     
      .then((response)=> {
        if(response.data ==='send'){
          alert('Message Sent');
        }else{
          alert('failed');
        }
         
      })
      .catch((error)=>alert(error))
    
  
  }
  


  return (
    <div className='home-container'>
        
         

            
 
    <Container fluid className="mt-5"> 
    
        <Row className="justify-content-center"> 
       
            <Col xs={12} md={6} lg={4}> 
            <GiCommercialAirplane size={25}  color='blue'/>
            <h3>Welcome to Travel Click</h3>
            <p>The Gateway to Global Travel</p>
           <a href='/Eujob'>
            <button  type='submit' name='submit'  
            className='btn btn-primary mb-4 float-right' > 
             Apply For Visa Sponsorship Job now 
             </button>
             </a>
                      
                <Card> 
                   
                    <Card.Body> 
                      
                        <Card.Title id='About'>About Us</Card.Title> 
                        <Card.Text > 
                        Welcome to Travel Click Visa Agents! We're here to 
                        make your visa application process smooth and hassle-free.
                         Whether you're traveling for work or leisure, we specialize
                          in helping applicants connect with partner employers abroad.
                          Travel Click specializes in helping individuals secure job opportunities
                           in Europe, UK, US, and Canada from our partner employers.
                            We provide comprehensive visa and job placement services
                             tailored to your career goals and destination preferences.

                            <span><a href='Faq'>Read more</a></span> 

                        </Card.Text> 
                    </Card.Body> 
                </Card> 
            </Col> 
            <Col xs={12} md={6} lg={6}> 
                <Card> 
                    <Card.Img 
                        variant="top"
                        src=""
                        alt=" Logo"
                    /> 
                    <Card.Body> 
                      <BsPassportFill size={50} color='green' />
                        <Card.Title id='Service'> Our Services Include:</Card.Title> 
                        <Card.Text> 
 <h5>Job opportunities</h5> 
 Travel Click specializes in helping individuals secure job opportunities in Europe, UK, US, and Canada from our partner employers. We provide comprehensive visa and job placement services tailored to your career goals and destination preferences.
     
                
<h5>Visa Consultation:</h5>

Expert guidance on visa requirements and application procedures.
Tailored advice based on your travel destination and purpose.

<h5>Visa Application Processing:</h5>

Efficient handling of visa applications to ensure accuracy and compliance.
Timely updates and communication throughout the application process.

<h5>Document Preparation:</h5>

Assistance with gathering necessary documents and ensuring they meet embassy requirements.
Detailed checklist provided for clarity and ease of preparation.

<h5>Appointment Scheduling:</h5>

Booking visa appointments at consulates or visa centers.
Managing appointment dates and times to suit your schedule.

<h5>Visa Follow-up and Updates:</h5>

Tracking application status and providing regular updates.
Resolving any queries or issues with visa authorities on your behalf.
                        </Card.Text> 
                    </Card.Body> 
                </Card> 
            </Col> 
        </Row> 

        <Row className="justify-content-center">
          <Col xs={12}  md={6}  lg={6}>
               <Card>
                    <Card.Body>
                          <Card.Title> Why Choose Us?</Card.Title>
                          <Card.Text>
                         

            Experience: Years of experience in handling visa applications across various countries and visa types.

            Customer Focus: Dedicated support from initial consultation to visa issuance.

            Efficiency: Streamlined processes to minimize delays and ensure timely visa approvals.

            Confidentiality: Strict adherence to data privacy laws and confidentiality of client information.
                          </Card.Text>
                    </Card.Body>
               </Card>
          </Col>


          <Col xs={12}  md={6}  lg={4}>
               <Card>
                    <Card.Body>
                          <Card.Title>  Client Testimonials</Card.Title>
                          <Card.Text>
                         
                         

Read what our clients have to say about their experience with Click Travel
 and how we helped make their travel plans hassle-free.
           
                          </Card.Text>
                    </Card.Body>
               </Card>
          </Col>

        </Row>


      <Row className='justify-content-center '>
        <Col xs={12}  md={6}  lg={6}>
               <Card>
                    <Card.Body>
                          <Card.Title>  Contact Us </Card.Title>
                          <Card.Text>
              Ready to get started? Contact us today for a consultation
              or to inquire about our visa services.
                          </Card.Text>

                  <Container id='Contact'>
                      <form>
                          <input className='form-control '  onChange={(e)=>setFullname(e.target.value)} value={fullname}
                           placeholder='Full name'  name='fullname' />

                           <input className='form-control '  onChange={(e)=>setEmail(e.target.value)} value={email}
                           placeholder='Email'  name='email' />

                          <textarea className='form-control mt-2' name='messages'
                           placeholder='Message' rows={10} onChange={(e)=>setMessage(e.target.value)} value={messages}/>
                          
                          <button  type='submit' name='submit' 
                          className='btn btn-primary mt-4' onClick={sendMes}> Send Message </button>
                      </form>

                  </Container>
                    </Card.Body>
               </Card>
          </Col>

        </Row>
    </Container> 
          
              

                 

           


            

       

    </div>
  )
}

export default Home