import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import joblist from '../data/joblist';
import countriesData from '../data/countries';
import euroCountries from '../data/euroCountries';


const Letterbuilder = () => {

 

  const [personalInfo, setPersonalInfo] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    country: ''
  });

  const [employerInfo, setEmployerInfo] = useState({
    employerName: '',
    employerAddress: '',
  
  });
  const [jobDestination, setJobDestination] = useState('Austria');
  const [jobTitle, setJobTitle] = useState('');
  const [educationList, setEducationList] = useState([{ school: '', location: '', year: '' }]);
  const [experienceList, setExperienceList] = useState([{ company: '', position: '', year: '' }]);
  const [skills, setSkills] = useState('');
  
  
  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const handleEmployerInfoChange = (e) => {
    const { name, value } = e.target;
    setEmployerInfo({ ...employerInfo, [name]: value });
  };

  const handleJobTitleChange = (e) => {
    setJobTitle(e.target.value);
  };
  const handleJobDestinationChange = (e) => {
    setJobDestination(e.target.value);
  };

  const handleEducationChange = (e, index) => {
    const { name, value } = e.target;
    const newList = [...educationList];
    newList[index][name] = value;
    setEducationList(newList);
  };

  const handleAddEducation = () => {
    setEducationList([...educationList, { school: '', location: '', year: '' }]);
  };

  const handleRemoveEducation = (index) => {
    const newList = [...educationList];
    newList.splice(index, 1);
    setEducationList(newList);
  };

  const handleExperienceChange = (e, index) => {
    const { name, value } = e.target;
    const newList = [...experienceList];
    newList[index][name] = value;
    setExperienceList(newList);
  };

  const handleAddExperience = () => {
    setExperienceList([...experienceList, { company: '', position: '', year: '' }]);
  };

  const handleRemoveExperience = (index) => {
    const newList = [...experienceList];
    newList.splice(index, 1);
    setExperienceList(newList);
  };

  
 
  
  const handleSubmit = (e) => {
    e.preventDefault();

     if (jobDestination ==='' || jobDestination ===null){
      alert('Select Job destination');
     }else if (personalInfo.country ==='' || personalInfo.country ===null){
      alert('Select Country');
    }else if (jobTitle ==='' || jobTitle ===null){
      alert('Select Job Title');
     }else{
    const formData = {
      jobTitle,
      jobDestination,
      employerName: employerInfo.employerName,
      employerAddress: employerInfo.employerAddress,
      fullName: personalInfo.fullName,
      email: personalInfo.email,
      phone: personalInfo.phone,
      address: personalInfo.address,
      country: personalInfo.country,
      education: educationList.map(item => `${item.school}, ${item.location}, ${item.year}`).join('\n'),
      experience: experienceList.map(item => `${item.company}, ${item.position}, ${item.year}`).join('\n'),
      skills,
     
    };

    axios.post('https://euro-employment.ayomitech.com/api/letterbuilder.php', formData)
      .then(response => {
        //alert(response.data);
        window.location.pathname='/Success';
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        // Handle error scenario (e.g., show error message)
      });
     }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Create Cover Letter</h2>
      <p>
      Ready to make a great first impression? Travel Click has 
                   assisted thousands of job seekers in crafting perfect cover 
                   letters tailored to their dream country. Whether you're applying
                    in the USA, UK, Australia, or elsewhere, our simple, user-friendly
                     cover letter builder helps you highlight your strengths and stand
                      out to employers. Start creating your customized cover letter today
                       by filling out the form below!
      </p>
      <form onSubmit={handleSubmit}>
        {/* Job Destnation */}
        <div className="form-group">
          <label htmlFor="jobDestination">Job Seeking Destination</label>
         
          <select
            id="jobDestination"
            className="form-control"
            name='jobDestination'
            value={jobDestination}
            onChange={handleJobDestinationChange}
            required
          >
             {
              euroCountries.map((data)=>{
                return(

              <option key={data.name} value={data.name}>{data?.name}</option>
            )}) }
          </select>
        </div>


        {/* Job Title Select Option */}
        <div className="form-group">
          <label htmlFor="jobTitle">Job Title</label>
         
          <select
            id="jobTitle"
            className="form-control"
            name='jobTitle'
            value={jobTitle}
            onChange={handleJobTitleChange}
            required
          >
             {
              joblist.map((data)=>{
                return(

              <option key={data.name} value={data.name}>{data?.name}</option>
            )}) }
          </select>
        </div>

        {/* Personal Information Section */}
        <h3>Personal Information</h3>
        <div className="form-group">
          <label htmlFor="fullName">Full Name</label>
          <input 
            type="text" 
            className="form-control" 
            id="fullName" 
            name="fullName" 
            value={personalInfo.fullName} 
            onChange={handlePersonalInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            className="form-control" 
            id="email" 
            name="email" 
            value={personalInfo.email} 
            onChange={handlePersonalInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input 
            type="tel" 
            className="form-control" 
            id="phone" 
            name="phone" 
            value={personalInfo.phone} 
            onChange={handlePersonalInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input 
            type="text" 
            className="form-control" 
            id="address" 
            name="address" 
            value={personalInfo.address} 
            onChange={handlePersonalInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <select
            id="country"
            className="form-control"
            name="country"
            value={personalInfo.country}
            onChange={handlePersonalInfoChange}
            required
          >
             {
              countriesData.map((data)=>{
                return(

              <option key={data.name} value={data.name}>{data?.name}</option>
            )}) }
          </select>
        </div>

        {/* Education Section */}
        <h3>Education</h3>
        {educationList.map((education, index) => (
          <div key={index} className="education-section">
            <div className="form-group">
              <label htmlFor={`school-${index}`}>School</label>
              <input 
                type="text" 
                className="form-control" 
                id={`school-${index}`} 
                name="school" 
                value={education.school} 
                onChange={(e) => handleEducationChange(e, index)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`location-${index}`}>Location</label>
              <input 
                type="text" 
                className="form-control" 
                id={`location-${index}`} 
                name="location" 
                value={education.location} 
                onChange={(e) => handleEducationChange(e, index)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`year-${index}`}>Year</label>
              <input 
                type="text" 
                className="form-control" 
                id={`year-${index}`} 
                name="year" 
                value={education.year} 
                onChange={(e) => handleEducationChange(e, index)} 
                required 
              />
            </div>
            {index === educationList.length - 1 && (
              <button type="button" className="btn btn-link" onClick={handleAddEducation}>
                + Add Education
              </button>
            )}
            {index !== 0 && (
              <button type="button" className="btn btn-link text-danger" onClick={() => handleRemoveEducation(index)}>
                - Remove
              </button>
            )}
          </div>
        ))}

        {/* Experience Section */}
        <h3>Experience</h3>
        
        {experienceList.map((experience, index) => (
          <div key={index} className="experience-section">
            <div className="form-group">
              <label htmlFor={`company-${index}`}>Company</label>
              <input 
                type="text" 
                className="form-control" 
                id={`company-${index}`} 
                name="company" 
                value={experience.company} 
                onChange={(e) => handleExperienceChange(e, index)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`position-${index}`}>Position</label>
              <input 
                type="text" 
                className="form-control" 
                id={`position-${index}`} 
                name="position" 
                value={experience.position} 
                onChange={(e) => handleExperienceChange(e, index)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`exp-year-${index}`}>Year</label>
              <input 
                type="text" 
                className="form-control" 
                id={`exp-year-${index}`} 
                name="year" 
                value={experience.year} 
                onChange={(e) => handleExperienceChange(e, index)} 
                required 
              />
            </div>
            {index === experienceList.length - 1 && (
              <button type="button" className="btn btn-link" onClick={handleAddExperience}>
                + Add Experience
              </button>
            )}
            {index !== 0 && (
              <button type="button" className="btn btn-link text-danger" onClick={() => handleRemoveExperience(index)}>
                - Remove
              </button>
            )}
          </div>
        ))}

        {/* Skills Section */}
        <h3>Additional Information</h3>
        <div className="form-group">
          <textarea 
            className="form-control" 
            rows="3" 
            value={skills} 
            onChange={(e) => setSkills(e.target.value)} 
            placeholder="Other Information" 
            required
          />
        </div>


         {/* Employer Information Section */}
         <h3>Employer Information</h3>
        <div className="form-group">
          <label htmlFor="employerName">Employer Name</label>
          <input 
            type="text" 
            className="form-control" 
            id="employerName" 
            name="employerName" 
            value={employerInfo.employerName} 
            onChange={handleEmployerInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="employerAddress">Employer Address</label>
          <input 
            type="employerAddress" 
            className="form-control" 
            id="employerAddress" 
            name="employerAddress" 
            value={employerInfo.employerAddress} 
            onChange={handleEmployerInfoChange} 
            required 
          />
        </div>

       

        {/* Submit Button */}
        <button type="submit" className="btn btn-success mt-5 mb-5">Request Cover Letter</button>
      </form>
    </div>
  );
};

export default Letterbuilder;
