import React from 'react';
import { FiMenu } from "react-icons/fi";
//import { FcGlobe } from "react-icons/fc";

function Header() {

 const showMenu = ()=>{
    let x = document.getElementById('header-list');
    if(x.style.display==='none'){
      x.style.display='block';
      
    }else{
      x.style.display='none';
    }

 }

  return (
    
        <header className='header-container'>
          <div className='logo-wrap'> 
          <FiMenu onClick={()=>showMenu()} size={25} className='menu-icon'/>
           
          </div>
          
             <div id='header-list' className='header-list'>
                <a href='/'>Home</a>
                <a href='/Cvbuilder'>Create Perfect CV</a>
                <a href='/Letterbuilder'>Create Cover Letter</a>
                <a href='/#Services'>Services</a>
                <a href='/#About'>About Us</a>
                <a href='/Faq'>FAQ</a>
            </div>
        </header>
    
  )
}

export default Header