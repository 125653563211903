import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';


const Success = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="p-4">
            <h2 className="text-center mb-4">Success!</h2>
            <p className="text-center">
              Your request has been successfully summited.
            </p>
            <p className="text-center">
              Thank you for using our service.
            </p>
            <p className="text-center" style={{color:'green'}}>
              A link has been sent to your email. check your 
              email to complete your Application.
            </p>
            <div style={{display:'none'}} className="text-center mt-4">
              
                <Button onClick={()=> window.location.href='google.com'} variant="primary">Make Payment 9.99 USD</Button>
              
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Success;
