


const euroCountries = [
    
    {name:"Austria"},
    {name:"Belgium"},
    {name:"Bulgaria"},
    {name:"Czechia"},
    {name:"Croatia"},
    {name:"Denmark"},
    {name:"Estonia"},
    {name:"Finland"},
    {name:"France"},
    {name:"Germany"},
    {name:"Greece"},
    {name:"Hungary"},
    {name:"Iceland"},
    {name:"Italy"},
    {name:"Latvia"},
    {name:"Lithuania"},
    {name:"Liechtenstein"},
    {name:"Luxemburg"},
    {name:"Malta"},
    {name:"Norway"},
    {name:"Netherlands"},
    {name:"Poland"},
    {name:"Portugal"},
    {name:"Romenia"},
    {name:"Spain"},
    {name:"Slovenia"},
    {name:" Sweden"},
    {name:"Switzerland"}
    
]
  


export default euroCountries