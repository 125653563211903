
import './App.css';
import Header from './Components/Header';
import Eujob from './Pages/Eujob';
import Home from './Pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './Components/Footer';
import Faq from './Pages/Faq';

import CVBuilder from './Pages/Cvbuilder';
import Letterbuilder from './Pages/Letterbuilder';
import Success from './Pages/Success';

function App() {
  return (
    <div className="App">
      <Header />
      
      <Router>
           <Routes>
               <Route path='/'  element={<Home />}/>
               <Route path='/Eujob'  element={<Eujob />}/>
              
               <Route path='/Cvbuilder'  element={<CVBuilder/>}/>
               <Route path='/Letterbuilder'  element={<Letterbuilder/>}/>
              
               <Route path='/Faq'  element={<Faq/>}/>
               <Route path='/Success'  element={<Success/>}/>
           </Routes>
      </Router>
     
     <Footer />
    </div>
  );
}

export default App;
