import React from 'react';

import { Container, Row, Col, Card, CardBody } from "react-bootstrap"; 
import "bootstrap/dist/css/bootstrap.min.css"; 



function Faq() {
  return (
    <div className='home-container'>
        
        <Container fluid className='mt-5'> 
    
         <Row className="justify-content-center">
              <Col xl={6} md={6} lg={4}>
              <Card>
                <CardBody>
                   <Card.Title  >FAQs for Travel Click </Card.Title>
                      
                  

<h5>1. What does Travel Click do? </h5>
   Travel Click specializes in helping individuals secure job opportunities in Europe, UK, US, and Canada from our partner employers. We provide comprehensive visa and job placement services tailored to your career goals and destination preferences.

<h5>2. How can Travel Click help me secure a job in Europe, UK, US, or Canada?</h5>
   We work closely with our network of partner employers to match your skills and qualifications with available job opportunities in your desired location. Our services include resume optimization, interview preparation, visa application assistance, and job placement support.

<h5>3. What types of job opportunities does Travel Click offer?</h5>
   We assist with a wide range of job opportunities across various industries, including IT, healthcare, engineering, finance, hospitality, and more. Whether you're seeking permanent, contract, or temporary positions, we can help you find the right job.

<h5>4. How does Travel Click handle visa applications?</h5>
   We provide expert guidance on visa requirements and assist with preparing
    and submitting visa applications for Europe, UK, US, and Canada. Our team
     ensures that all documentation is complete and accurate to enhance your chances of approval.

<h5>5. Are there any guarantees that I will secure a job through Travel Click?</h5>
   While we cannot guarantee job placement (as hiring decisions are made by employers),
    we strive to connect you with suitable job opportunities based on your qualifications
     and preferences. Our personalized approach maximizes your chances of success. 
     Once your CV meets the employer's requirements, they will call you for an interview. 
     If the interview goes well and the employer is happy, they will hire you. 
     We'll work hard to ensure you get hired once you submit a perfect CV for the job.



<h5>6. How much does Travel Click's services cost?</h5>
   Costs vary depending on the specific services you require, such as resume
    writing, visa application assistance, and job placement support. We offer
     transparent pricing and tailored packages to suit your needs and budget.

<h5>7. How do I get started with Travel Click?</h5>
   Simply contact us through our website or by phone to schedule a consultation.
    We will discuss your career goals, assess your qualifications, and tailor a 
    personalized plan to help you secure a job in Europe, UK, US, or Canada.

<h5>8. Can Travel Click assist with urgent job placements and visa applications?</h5>
   Yes, we understand the urgency of job placements and can expedite the process whenever 
   possible. Our team is experienced in handling urgent job opportunities and fast-tracking 
   visa applications based on your specific timeline.

<h5>9. How long does the job placement process typically take with Travel Click?</h5>
   The timeframe varies depending on factors such as your qualifications, job market 
   conditions, and visa processing times. We strive to streamline the process and keep 
   you informed at every step to ensure a smooth job placement experience.

<h5>10. What should I do if I have further questions or need assistance?</h5>
   Feel free to reach out to our friendly team at Travel Click via phone, email, or through 
   our website's contact form. We are here to answer your questions, provide guidance, 
   and support you throughout your job search and relocation process.

<p>These FAQs aim to provide clarity and assurance to individuals considering Travel Click's services for
    securing job opportunities in Europe, UK, US, and Canada through our network of partner employers.</p>


                   <Card.Text>
                    
                   </Card.Text>      
                    
                        
                </CardBody>
             </Card>
             </Col>
        </Row>

         


        </Container> 


    </div>
          
              
  )
}

export default Faq