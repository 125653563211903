import React,{useState} from 'react';

import { Container, Row, Col, Card, CardBody } from "react-bootstrap"; 
import "bootstrap/dist/css/bootstrap.min.css"; 

import axios from 'axios';
import euroCountries from '../data/euroCountries';
import joblist from '../data/joblist';
import countriesData from '../data/countries';



function Eujob() {

  const [fullname, setFullname]= useState('');
  const [email, setEmail]= useState('');
  const [phonenumber, setPhonenumber]= useState('');
  const [destination, setDestination]= useState('');
  const [job, setJob]= useState('');
  const [nationality, setNationality]= useState('');
  const [gender, setGender]= useState('');
  const [file, setFile]= useState(null);
  const [passportexp, setPassportexp]= useState('');
  
  

const submitApp =(e) =>{

  e.preventDefault();

  if (destination ==='' || destination ===null){
    alert('Select Job destination');
   }else if (nationality ==='' || nationality ===null){
    alert('Select Nationality');
  }else if (job ==='' || job ===null){
    alert('Select Job Title');
   }else{

  const fData = new FormData();
  fData.append('email', email);
  fData.append('fullname', fullname);
  fData.append('phonenumber', phonenumber);
  fData.append('destination', destination);
  fData.append('job', job);
  fData.append('nationality', nationality);
  fData.append('gender', gender);
  fData.append('file', file);
  fData.append('passportexp', passportexp);
  
   axios.post('https://euro-employment.ayomitech.com/api/euroapp.php', fData)
   
    .then((response)=> {
      if(response.data ==='send'){
        //alert('Application Submited');
        window.location.pathname='/Success';
      }else{
        alert(response.data);
      }
       
    })
    .catch((error)=>alert(error))
  
  }
}

  return (
    <div className='home-container'>
        
        <Container fluid className='mt-5'> 
    
         <Row className="justify-content-center">
              <Col xl={6} md={6} lg={4}>
              <Card>
                <CardBody>
                   <Card.Title>Apply for Euro Visa Sponsorship Job  </Card.Title>
                   <p>We work partner with employers accross europe that are hiring workers</p>
                      <p>Free work permit documents, tickets and accomodations.
                         These are provided by the employers. </p>

                   <Card.Text>
                    <h5>Home it Works?</h5>
                    <ul>
                      <li>Fill out the form below.</li>     
                       <li>We'll send your CV to our partner employers.</li>
                       <li>If selected, the employer will contact you for an interview.</li>
                       <li>Make sure your CV is in the standard European format to avoid rejection. <span><a href='/Cvbuilder'> 
                               Create Professional CV now</a></span></li>
                       <li>Also, ensure your international passport is valid for at least one year.</li>
                       <li>For best results, highlight your skills and experience relevant to the job you're applying for. </li>
                    </ul>
                   </Card.Text>

                    <h5>Fill the below form to apply now</h5>
                   <form className='m-4' onSubmit={submitApp}>
                   <label> Which country do you wish to work?</label>
                           <select className='form-control mb-2'  onChange={(e)=>setDestination(e.target.value)} value={destination} required>
                               {
                                euroCountries.map((data)=>{
                                 return(
            
                                <option key={data.name} value={data.name}>{data?.name}</option>
                              )}) }
                           </select>
                           
                           <label>Select Job Title?</label>
                           <select className='form-control mb-2'  onChange={(e)=>setJob(e.target.value)} value={job}  required>
                           {
                                joblist.map((data)=>{
                                 return(
                                <option key={data.name} value={data.name}>{data?.name}</option>
                              )}) }
                           </select>
                           
                           <label> When will your Passport expired?</label>
                           <input type='date' className='form-control mb-2' 
                            name='passport-expire' 
                            onChange={(e)=>setPassportexp(e.target.value)} value={passportexp} required/>
                         
                          <input type='text' className='form-control mb-2'  
                           placeholder='Full Name'  name='fullname' 
                           onChange={(e)=>setFullname(e.target.value)} value={fullname} required />
                           
                            <input className='form-control mb-2 '  
                           placeholder='Email '  name='email'  
                           onChange={(e)=>setEmail(e.target.value)} value={email} required/>
                           
                           <input type='phonenumber' className='form-control mb-2 '  
                           placeholder='Phone Number '  name='phonenumber'
                           onChange={(e)=>setPhonenumber(e.target.value)} value={phonenumber}  required />
                           
                           <label>Nationality</label>
                           <select className='form-control mb-2'  onChange={(e)=>setNationality(e.target.value)} value={nationality}  required>
                           {
                                countriesData.map((data)=>{
                                 return(
                                <option key={data.name} value={data.name}>{data?.name}</option>
                              )}) }
                           </select>
                           
                           <div>
                            <label htmlFor='Female'>Female</label>
                           <input type='radio'  className='m-2'
                             name='gender'  onChange={(e)=>setGender(e.target.value)} 
                             value='Female' id='Female'/>
                           
                           <label htmlFor='Male'>Male</label>
                           <input type='radio'   className='m-2 '
                            name='gender'  onChange={(e)=>setGender(e.target.value)} 
                            value='Male' id='Male' />

                           </div>

                           <label className=' mt-4 '>Upload CV/Resume</label>
                           <input type='file'  name='file' className='form-control'
                             onChange ={(e)=>setFile(e.target.files[0])}    required/>

                          
                          <button  type='submit' name='submit' 
                          className='btn btn-success mt-4 mb-5' > Submit Application </button>
                      </form>
                        
                    
                        
                </CardBody>
             </Card>
             </Col>
        </Row>

         


        </Container> 


    </div>
          
              
  )
}

export default Eujob