import React from 'react';


function Footer() {

 

  return (
    
        <footer className='footer-container'>
                 
             <div className='footer-list'>
                <a href='#'>@ 2024 Reserved</a>
                <a href='/Privacy'>Privacy</a>
                <a href='/Faq'>FAQ</a>
                <a href='/#Contact'>Contact</a>
            </div>
        </footer>
    
  )
}

export default Footer