


const joblist = [
    {name:"Construction Worker"},
    {name:"Factory Worker"},
    {name:"Clearner"},
    {name:"Warehouse Worker"},
    {name:"Maintenance Technician"},
    {name:"Logistics"},
    {name:"Factory Supervisor"},
   
]
  


export default joblist