import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import countriesData from '../data/countries';
import joblist from '../data/joblist';
import euroCountries from '../data/euroCountries';


const Cvbuilder = () => {
  const [personalInfo, setPersonalInfo] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    country: ''
  });

  const [jobDestination, setjobDestination] = useState('Austria');
  const [jobTitle, setJobTitle] = useState('');
  const [educationList, setEducationList] = useState([{ school: '', location: '', year: '' }]);
  const [experienceList, setExperienceList] = useState([{ company: '', position: '', year: '' }]);
  const [skills, setSkills] = useState('');
  const [certifications, setCertifications] = useState('');
  const [languages, setLanguages] = useState('');
  const [references, setReferences] = useState('');



  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo({ ...personalInfo, [name]: value });
  };

  const handleDestinationChange = (e) => {
    setjobDestination(e.target.value);
  };

  const handleJobTitleChange = (e) => {
    setJobTitle(e.target.value);
  };


  const handleEducationChange = (e, index) => {
    const { name, value } = e.target;
    const newList = [...educationList];
    newList[index][name] = value;
    setEducationList(newList);
  };

  const handleAddEducation = () => {
    setEducationList([...educationList, { school: '', location: '', year: '' }]);
  };

  const handleRemoveEducation = (index) => {
    const newList = [...educationList];
    newList.splice(index, 1);
    setEducationList(newList);
  };

  const handleExperienceChange = (e, index) => {
    const { name, value } = e.target;
    const newList = [...experienceList];
    newList[index][name] = value;
    setExperienceList(newList);
  };

  const handleAddExperience = () => {
    setExperienceList([...experienceList, { company: '', position: '', year: '' }]);
  };

  const handleRemoveExperience = (index) => {
    const newList = [...experienceList];
    newList.splice(index, 1);
    setExperienceList(newList);
  };

  
 
  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (jobDestination ==='' || jobDestination ===null){
      alert('Select Job destination');
     }else if (personalInfo.country ==='' || personalInfo.country ===null){
      alert('Select Country');
    }else if (jobTitle ==='' || jobTitle ===null){
      alert('Select Job Title');
     }else{

    const formData = {
      jobDestination,
      jobTitle,
      fullName: personalInfo.fullName,
      email: personalInfo.email,
      phone: personalInfo.phone,
      address: personalInfo.address,
      country: personalInfo.country,
      education: educationList.map(item => `${item.school}, ${item.location}, ${item.year}`).join('\n'),
      experience: experienceList.map(item => `${item.company}, ${item.position}, ${item.year}`).join('\n'),
      skills,
      certifications,
      languages,
      references
    };

    

    axios.post('https://euro-employment.ayomitech.com/api/cvbuilder.php', formData)
      .then(response => {
        window.location.pathname='/Success';
       
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        // Handle error scenario (e.g., show error message)
      });
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Create CV/Resume</h2>
      <p>
                   Looking to land your dream job abroad? Travel Click has helped thousands
                    of applicants create perfect, standard format resumes tailored to your 
                    dream country. Whether you're aiming for a position in the USA, UK,
                     Australia, or beyond, our user-friendly CV/Resume builder ensures your 
                     application stands out. Start crafting your ideal resume today by filling
                      out the form below!. 

                   </p>
      <form onSubmit={handleSubmit}>

       {/* Job Destnation */}
       <div className="form-group">
          <label htmlFor="jobDestination">Job Seeking Destination</label>
         
          <select
            id="jobDestination"
            className="form-control"
            name='jobDestination'
            value={jobDestination}
            onChange={handleDestinationChange}
            required
          >
             {
              euroCountries.map((data)=>{
                return(

              <option key={data.name} value={data.name}>{data?.name}</option>
            )}) }
          </select>
        </div>

        {/* Job Title Select Option */}
        <div className="form-group">
          <label htmlFor="jobTitle">Job Title</label>
          <select
            id="jobTitle"
            className="form-control"
            name='jobTitle'
            value={jobTitle}
            onChange={handleJobTitleChange}
            required
          >
             {
              joblist.map((data)=>{
                return(

              <option key={data.name} value={data.name}>{data?.name}</option>
            )}) }
          </select>
        </div>

        {/* Personal Information Section */}
        <h3>Personal Information</h3>
        <div className="form-group">
          <label htmlFor="fullName">Full Name</label>
          <input 
            type="text" 
            className="form-control" 
            id="fullName" 
            name="fullName" 
            value={personalInfo.fullName} 
            onChange={handlePersonalInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input 
            type="email" 
            className="form-control" 
            id="email" 
            name="email" 
            value={personalInfo.email} 
            onChange={handlePersonalInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input 
            type="tel" 
            className="form-control" 
            id="phone" 
            name="phone" 
            value={personalInfo.phone} 
            onChange={handlePersonalInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input 
            type="text" 
            className="form-control" 
            id="address" 
            name="address" 
            value={personalInfo.address} 
            onChange={handlePersonalInfoChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <select
            id="country"
            className="form-control"
            name="country"
            value={personalInfo.country}
            onChange={handlePersonalInfoChange}
            required
          >
             {
              countriesData.map((data)=>{
                return(

              <option key={data.name} value={data.name}>{data?.name}</option>
            )}) }
          </select>
        </div>

        {/* Education Section */}
        <h3>Education</h3>
        {educationList.map((education, index) => (
          <div key={index} className="education-section">
            <div className="form-group">
              <label htmlFor={`school-${index}`}>School</label>
              <input 
                type="text" 
                className="form-control" 
                id={`school-${index}`} 
                name="school" 
                value={education.school} 
                onChange={(e) => handleEducationChange(e, index)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`location-${index}`}>Location</label>
              <input 
                type="text" 
                className="form-control" 
                id={`location-${index}`} 
                name="location" 
                value={education.location} 
                onChange={(e) => handleEducationChange(e, index)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`year-${index}`}>Year</label>
              <input 
                type="text" 
                className="form-control" 
                id={`year-${index}`} 
                name="year" 
                value={education.year} 
                onChange={(e) => handleEducationChange(e, index)} 
                required 
              />
            </div>
            {index === educationList.length - 1 && (
              <button type="button" className="btn btn-link" onClick={handleAddEducation}>
                + Add Education
              </button>
            )}
            {index !== 0 && (
              <button type="button" className="btn btn-link text-danger" onClick={() => handleRemoveEducation(index)}>
                - Remove
              </button>
            )}
          </div>
        ))}

        {/* Experience Section */}
        <h3>Experience</h3>
        
        {experienceList.map((experience, index) => (
          <div key={index} className="experience-section">
            <div className="form-group">
              <label htmlFor={`company-${index}`}>Company</label>
              <input 
                type="text" 
                className="form-control" 
                id={`company-${index}`} 
                name="company" 
                value={experience.company} 
                onChange={(e) => handleExperienceChange(e, index)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`position-${index}`}>Position</label>
              <input 
                type="text" 
                className="form-control" 
                id={`position-${index}`} 
                name="position" 
                value={experience.position} 
                onChange={(e) => handleExperienceChange(e, index)} 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor={`exp-year-${index}`}>Year</label>
              <input 
                type="text" 
                className="form-control" 
                id={`exp-year-${index}`} 
                name="year" 
                value={experience.year} 
                onChange={(e) => handleExperienceChange(e, index)} 
                required 
              />
            </div>
            {index === experienceList.length - 1 && (
              <button type="button" className="btn btn-link" onClick={handleAddExperience}>
                + Add Experience
              </button>
            )}
            {index !== 0 && (
              <button type="button" className="btn btn-link text-danger" onClick={() => handleRemoveExperience(index)}>
                - Remove
              </button>
            )}
          </div>
        ))}

        {/* Skills Section */}
        <h3>Skills</h3>
        <div className="form-group">
          <textarea 
            className="form-control" 
            rows="3" 
            value={skills} 
            onChange={(e) => setSkills(e.target.value)} 
            placeholder="Enter your skills (comma separated)" 
            required
          />
        </div>

        {/* Certifications Section */}
        <h3>Certifications</h3>
        <div className="form-group">
          <textarea 
            className="form-control" 
            rows="3" 
            value={certifications} 
            onChange={(e) => setCertifications(e.target.value)} 
            placeholder="Enter your certifications (comma separated)" 
            required
          />
        </div>

        {/* Languages Section */}
        <h3>Languages</h3>
        <div className="form-group">
          <textarea 
            className="form-control" 
            rows="3" 
            value={languages} 
            onChange={(e) => setLanguages(e.target.value)} 
            placeholder="Enter languages you know (comma separated)" 
            required
          />
        </div>

        {/* References Section */}
        <h3>References</h3>
        <div className="form-group">
          <textarea 
            className="form-control" 
            rows="3" 
            value={references} 
            onChange={(e) => setReferences(e.target.value)} 
            placeholder="Enter references (comma separated)" 
            required
          />
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-success mb-5"> Create CV/Resume</button>
      </form>
    </div>
  );
};

export default Cvbuilder;
